<i18n>
ru:
  apply: Применить
  text: Текст пасхалки
  title: Заголовок пасхалки
ua:
  apply: Застосувати
  text: Текст пасхалки
  title: Заголовок пасхалки
us:
  apply: Apply
  text: Easter egg text
  title: Easter egg title
</i18n>

<template>
  <div
    v-if="
      clientStore.ClientState.data?.EasterEggPromo &&
      !stringIsNullOrWhitespace(clientStore.ClientState.data?.EasterEggPromo)
    "
    class="v-easter-egg v-d-flex v-flex-column"
  >
    <span
      class="v-easter-egg--title v-font-weight-600 v-mb-xs"
      v-html="translate('easterEggPopup.title')"
    />
    <span
      class="v-mb-sm"
      v-html="translate('easterEggPopup.text')"
    />
    <arora-button
      class-name="v-btn v-btn-secondary v-d-flex v-align-items-center v-easter-egg--button"
      ignore-settings
      :label="clientStore.ClientState.data?.EasterEggPromo"
      @click="buttonClick"
    >
      <div class="v-w-100 v-text-center v-position-relative">
        <span v-html="clientStore.ClientState.data?.EasterEggPromo" />
        <common-copy-content
          class-name-container="v-position-absolute v-easter-egg--copy-container"
          class-name-content="v-easter-egg--copy"
          :content="clientStore.ClientState.data?.EasterEggPromo"
        />
      </div>
    </arora-button>
    <div class="v-w-100 v-d-flex v-flex-row v-justify-content-end">
      <arora-button
        class-name="v-btn v-btn-link-secondary v-btn-text v-no-underline v-profile-field-input__change-btn"
        ignore-settings
        :label="translate('easterEggPopup.apply')"
        @click="apply"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useCommon } from '@arora/common'

const clientStore = useClientStore()
const popupStore = usePopupStore()

const { translate } = useI18nSanitized()
const { copyContent, stringIsNullOrWhitespace } = useCommon()

async function buttonClick(): Promise<void> {
  const copyResponse = await copyContent(clientStore.ClientState.data?.EasterEggPromo ?? '')
  if (copyResponse) {
    setTimeout(() => popupStore.closePopup(), 1500)
  }
}

function apply(): void {
  if (!clientStore.ClientState.data?.EasterEggPromo) return

  clientStore
    .applyPromoCode(clientStore.ClientState.data?.EasterEggPromo)
    .then(() => popupStore.closePopup())
}
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

.v-easter-egg {
  &--title {
    font-size: 1.25rem;
  }

  .v-btn-secondary {
    svg {
      fill: none;
    }
  }

  &--button {
    min-height: 42px;
  }

  &--copy-container,
  &--copy {
    width: 24px;
    height: 24px;
  }

  &--copy-container {
    right: 4px;
    bottom: 0;
  }

  &--copy {
    path {
      @include mixins.trans;
      stroke: variables.$SecondaryColor;
    }
  }
}
</style>
